import { useAdminCreateVariant } from "medusa-react"
import React, { useEffect, useState, useContext } from "react"
import Button from "../../../../components/fundamentals/button"
import EditIcon from "../../../../components/fundamentals/icons/edit-icon"
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import IconTooltip from "../../../../components/molecules/icon-tooltip"
import Input from "../../../../components/molecules/input"
import TagInput from "../../../../components/molecules/tag-input"
import BodyCard from "../../../../components/organisms/body-card"
import VariantGrid from "../../../../components/variant-grid"
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"
import { getCombinations } from "../../../../utils/get-combinations"
import NewOption from "../../details/variants/option-edit"
import VariantEditor from "../../details/variants/variant-editor"
import { useProductForm } from "../form/product-form-context"
import { buildOptionsMap } from "../utils"
import { ProductOption, DurationOption } from "../../../../types/shared"
import { AccountContext } from "../../../../context/account"

const Variants = ({ isEdit, product, isSubscription = false }) => {
  const account = useContext(AccountContext)

  const {
    setValue,
    setVariants,
    variants,
    productOptions,
    setProductOptions,
  } = useProductForm()
  const [showAddVariantModal, setShowAddVariantModal] = useState(false)
  const [showAddOption, setShowAddOption] = useState(false)
  const notification = useNotification()
  const createVariant = useAdminCreateVariant(product?.id)

  const questions = product?.variants?.[0]?.surveys?.[0]?.questions
  const survey_id = product?.variants?.[0]?.surveys?.[0]?.id
  // console.log('questions', questions)

  // useEffect(() => {
  //   if (isEdit) {
  //     return
  //   }

  //   const os = [...productOptions]
  //   // console.log('os: ', os)
  //   const combinations = getCombinations(os)
  //   // console.log('combinations: ', combinations)

  //   const newVariants = combinations.map((optionValues) => {
  //     if (!optionValues) {
  //       return null
  //     }

  //     const existing = variants.find((v) =>
  //       v.options.every((value, index) => optionValues[index] === value)
  //     ) || { prices: [] }

  //     existing.options = optionValues.filter((v) => v !== "")

  //     return existing
  //   })

  //   // console.log('newVariants.filter((v) => !!v)', newVariants.filter((v) => !!v))
  //   setVariants(newVariants.filter((v) => !!v))
  // }, [productOptions])

  // console.log("product options", productOptions)
  useEffect(() => {
    if (isEdit) return
    // console.log("init")
    // if (productOptions.filter(({ name }) => name === ProductOption.DURATION).length === 0) {
    //   setProductOptions([
    //     ...productOptions,
    //     {
    //       name: "Duration",
    //       values: [DurationOption.MONTH, DurationOption.YEAR],
    //     },
    //   ])
    // }
  }, [productOptions])

  const updateOptionValue = (index, values) => {
    const newOptions = [...productOptions]
    newOptions[index] = {
      ...newOptions[index],
      values,
    }

    setValue(`options[${index}].values`, values)
    setProductOptions(newOptions)
  }

  const handleRemoveOption = (index) => {
    const newOptions = [...productOptions]
    newOptions.splice(index, 1)
    setProductOptions(newOptions)
  }

  const handleAddOption = (e) => {
    setProductOptions([
      ...productOptions,
      {
        name: "",
        values: [],
      },
    ])
  }

  const updateOptionName = (e, index) => {
    const element = e.target
    const newOptions = [...productOptions]
    newOptions[index] = {
      ...newOptions[index],
      name: element.value,
    }

    setValue(`options[${index}].name`, element.value)
    setProductOptions(newOptions)
  }

  const handleAddVariant = (data) => {
    const newVariant = {
      ...data,
      inventory_quantity: data.inventory_quantity || 0,
    }

    createVariant.mutate(newVariant, {
      onSuccess: () => {
        notification("Success", "Successfully added a variant", "success")
        setShowAddVariantModal(false)
      },
      onError: (err) => {
        notification("Error", getErrorMessage(err), "error")
      },
    })
  }

  return (
    <BodyCard
      title="Variants"
      subtitle="Add variations of this product. Offer your customers different options for price, color, format, size, shape, etc."
      forceDropdown={true}
      actionables={
        isEdit &&
        !account?.isVendor && [
          {
            label: "Add variant",
            onClick: () => setShowAddVariantModal(true),
            icon: <PlusIcon size={20} />,
          },
          // {
          //   label: "Edit options",
          //   onClick: () => setShowAddOption(true),
          //   icon: <EditIcon size={20} />,
          // },
        ]
      }
    >
      <div>
        {!isEdit && (
          <>
            {/* <div className="flex items-center mb-base">
              <h6 className="inter-base-semibold text-grey-90 mr-1.5">
                Product Options
              </h6>
            </div>
            <div className="flex flex-col gap-y-base max-w-[570px] w-full mb-4">
              {productOptions.map((o, index) => (
                <div key={index} className="flex items-center">
                  <div className="flex gap-x-small grow">
                    <Input
                      required
                      className="w-[144px]"
                      name={`options[${index}].name`}
                      onChange={(e) => updateOptionName(e, index)}
                      label="Option title"
                      placeholder="Color"
                      value={o?.name || o.title}
                    />
                    <TagInput
                      className="grow"
                      placeholder="Blue, Green"
                      values={o?.values}
                      onChange={(values) => updateOptionValue(index, values)}
                    />
                  </div>
                  <button
                    className="ml-large"
                    onClick={() => handleRemoveOption(index)}
                  >
                    <TrashIcon className="text-grey-40" />
                  </button>
                </div>
              ))}
              <div className="mt-xs">
                <Button onClick={handleAddOption} size="small" variant="ghost">
                  + Add an option
                </Button>
              </div>
            </div> */}
            <div className="flex justify-center mb-base flex-col space-y-2">
              <div className="flex space-x-2">
                <h6 className="inter-base-semibold text-blue-600">
                  You need to create the product first, then variants can be
                  created
                </h6>
                {/* <IconTooltip content="Add product options to create variants" /> */}
              </div>
            </div>
          </>
        )}
        <VariantGrid
          edit={isEdit}
          product={product}
          variants={variants}
          onVariantsChange={(vars) => setVariants(vars)}
          isSubscription={isSubscription}
        />
      </div>
      {showAddOption && (
        <NewOption
          productId={product.id}
          options={product.options}
          onDismiss={() => setShowAddOption(false)}
        />
      )}
      {showAddVariantModal && (
        <VariantEditor
          onCancel={() => setShowAddVariantModal(false)}
          onSubmit={handleAddVariant}
          title="Add variant"
          optionsMap={buildOptionsMap(product)}
          isSubscription={isSubscription}
          survey_id={survey_id}
          defaultDescription={product?.description}
          defaultDescriptionRows={product?.metadata?.descriptions}
          redeemMethod={product?.redeem_method}
        />
      )}
    </BodyCard>
  )
}

export default Variants
