import moment from "moment"
import React, { useMemo } from "react"
import { SubscriptionStatusComponent } from "../../../domain/subscriptions/details/subscription-status"
import { getColor } from "../../../utils/color"
import Tooltip from "../../atoms/tooltip"
import CustomerAvatarItem from "../../molecules/customer-avatar-item"
import Table from "../../molecules/table"

const customerSubscriptionTableColumns = () => {
  const formatDuration = (value) => {
    return <div>{value}</div>
  }
  const columns = useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "customer",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>
            <Tooltip content={value.email}>
              <div>{value.email}</div>
            </Tooltip>
          </Table.Cell>
        ),
      },
      {
        Header: "login_user_id",
        accessor: "login_user_id",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{value}</Table.Cell>
        ),
      },
      // {
      //   Header: "login_source",
      //   accessor: "login_source",
      //   Cell: ({ cell: { value }, index }) => (
      //     <Table.Cell key={index}>{value}</Table.Cell>
      //   ),
      // },
      // {
      //   Header: "recurring",
      //   accessor: "is_recurring",
      //   Cell: ({ cell: { value }, index }) => (
      //     <Table.Cell key={index}>{value ? "Yes" : "No"}</Table.Cell>
      //   ),
      // },
      {
        Header: "duration",
        accessor: "duration",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{formatDuration(value)}</Table.Cell>
        ),
      },
      {
        Header: "start_date",
        accessor: "start_date",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
              {moment(value).format("YYYY-MM-DD")}
            </Tooltip>
          </Table.Cell>
        ),
      },
      {
        Header: "end_date",
        accessor: "end_date",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            <Tooltip content={moment(value).format("YYYY-MM-DD HH:mm")}>
              {moment(value).format("YYYY-MM-DD")}
            </Tooltip>
          </Table.Cell>
        ),
      },
      {
        Header: "status",
        accessor: "status",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            <SubscriptionStatusComponent status={value} />
          </Table.Cell>
        ),
      },
    ],
    []
  )

  return [columns]
}

export default customerSubscriptionTableColumns
