import Button from "../../../../components/fundamentals/button"
import Modal from "../../../../components/molecules/modal"
import React, { useState } from "react"
import Input from "../../../../components/molecules/input"
import medusaRequest from "../../../../services/request"

const GenerateCodesModal = ({ parentId, handleCancel, fetchChildDiscount }) => {
  const [quantity, setQuantity] = useState(0)

  async function generateNewCodes(quantity) {
    const path = `/admin/discounts/${parentId}/batch-create`
    const { data } = await medusaRequest("POST", path, { quantity })
  }

  return (
    <Modal handleClose={handleCancel}>
      <Modal.Header handleClose={handleCancel}>
        <span className="inter-xlarge-semibold">
          Generate Random Unique Codes
        </span>
      </Modal.Header>
      <Modal.Content>
        <div className="flex flex-col">
          <Input
            label="Quantity"
            type="number"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value)
            }}
          />
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="flex w-full h-8 justify-between">
          <Button
            variant="nuclear"
            className="mr-2 w-32 text-small justify-center"
            size="large"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            size="large"
            className="w-32 text-small justify-center"
            variant="primary"
            disabled={quantity <= 0}
            onClick={async () => {
              if (quantity > 1000) {
                alert("Max 1000")
                return
              }
              await generateNewCodes(quantity)
              await fetchChildDiscount()
              handleCancel()
            }}
          >
            Complete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default GenerateCodesModal
