import React, { useEffect, useMemo, useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import Spinner from "../../../components/atoms/spinner"
import CancelIcon from "../../../components/fundamentals/icons/cancel-icon"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import BodyCard from "../../../components/organisms/body-card"
import useImperativeDialog from "../../../hooks/use-imperative-dialog"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import Medusa from "../../../services/api"
import CustomerSubscriptionLogTable from "../../../components/templates/customer-subscription-log-table"
import TrashIcon from "../../../components/fundamentals/icons/trash-icon"
import { SubscriptionStatusComponent } from "./subscription-status"

const SubscriptionDetail = ({ id }) => {
  const notification = useNotification()

  const dialog = useImperativeDialog()

  const [isLoading, setIsLoading] = useState(true)

  const [subscription, setSubscription] = useState(null)

  const getSubscription = async (id) => {
    const resp = await Medusa.subscriptions.retrieve(id)
    const d = resp.data
    if (d.code !== 200) {
      setIsLoading(false)
      notification("Error", "get subscription error", "error")
      return
    }

    setSubscription(d.data)
    setIsLoading(false)

    console.log("raw subscription", d.data)
  }

  useEffect(() => {
    if (id) {
      getSubscription(id)
    }
  }, [])

  const handleCancelSubscription = async () => {
    const shouldCancel = await dialog({
      heading: "Cancel subscription",
      text: "Are you sure you want to cancel the subscription?",
    })

    if (!shouldCancel) {
      return
    }

    const resp = await Medusa.subscriptions.stop(subscription.id)
    const d = resp.data
    if (d.code !== 200) {
      notification("Error", "stop subscription error", "error")
      return
    }
    notification("Success", "stop subscription success", "success")
    getSubscription(subscription.id)
  }

  const handleTerminateSubscription = async () => {
    const shouldCancel = await dialog({
      heading: "Terminate subscription",
      text: "Are you sure you want to terminate the subscription?",
    })

    if (!shouldCancel) {
      return
    }

    const resp = await Medusa.subscriptions.terminate(subscription.id)
    const d = resp.data
    if (d.code !== 200) {
      notification("Error", "terminate subscription error", "error")
      return
    }
    notification("Success", "terminate subscription success", "success")
    getSubscription(subscription.id)
  }

  return (
    <div>
      <Breadcrumb
        currentPage={"Subscription Details"}
        previousBreadcrumb={"Subscription"}
        previousRoute="/a/subscriptions"
      />
      {isLoading || !subscription ? (
        <BodyCard className="w-full pt-2xlarge flex items-center justify-center">
          <Spinner size={"large"} variant={"secondary"} />
        </BodyCard>
      ) : (
        <div className="py-large">
          <div className="flex flex-col w-7/12 h-full">
            <BodyCard
              className={"w-full mb-4 min-h-0 h-auto"}
              title="Subscription plan"
              forceDropdown={true}
              status={
                <SubscriptionStatusComponent status={subscription?.status} />
              }
              actionables={[
                {
                  label: "Terminate Subscription",
                  icon: <CancelIcon size={"20"} />,
                  variant: "danger",
                  onClick: () => handleTerminateSubscription(),
                },
                {
                  label: "Cancel Subscription",
                  icon: <CancelIcon size={"20"} />,
                  variant: "danger",
                  onClick: () => handleCancelSubscription(),
                },
              ]}
            >
              <div className="flex justify-between mt-4">
                <div className="inter-small-regular text-grey-90">Login User Id</div>
                <div className="inter-small-regular text-grey-90 mr-3">
                  {subscription.login_user_id}
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="inter-small-regular text-grey-90">Name</div>
                <div className="inter-small-regular text-grey-90 mr-3">
                  {subscription.title}
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="inter-small-regular text-grey-90">
                  Recurring
                </div>
                <div className="inter-small-regular text-grey-90 mr-3">
                  {subscription.is_recurring ? "YES" : "NO"}
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="inter-small-regular text-grey-90">Duration</div>
                <div className="inter-small-regular text-grey-90 mr-3">
                  {subscription.duration}
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="inter-small-regular text-grey-90">
                  Start Date
                </div>
                <div className="inter-small-regular text-grey-90 mr-3">
                  {subscription.start_date.slice(0, 10)}
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="inter-small-regular text-grey-90">End Date</div>
                <div className="inter-small-regular text-grey-90 mr-3">
                  {subscription.end_date.slice(0, 10)}
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="inter-small-regular text-grey-90">
                  Descriptions
                </div>
                <div className="inter-small-regular text-grey-90 mr-3">
                  {subscription.descriptions?.map((s) => {
                    return <div key={s}>{s}</div>
                  })}
                </div>
              </div>
            </BodyCard>
          </div>
          <BodyCard
            className={"w-full mb-4 min-h-0 h-auto"}
            title="Subscription logs"
          >
            <div className="inter-small-regular text-grey-90 mr-3">
              <CustomerSubscriptionLogTable logs={subscription.logs} />
            </div>
          </BodyCard>
        </div>
      )}
    </div>
  )
}

export default SubscriptionDetail
