export const redemptionCodeExportPropertiesDescriptors = [
  {
    label: "Code",
    key: "code",
  },
  {
    label: "Order Num",
    key: "order.order_display_id",
  },
  {
    label: "Email",
    key: "order.email",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Created At",
    key: "created_at",
  },
  {
    label: "Delivered At",
    key: "delivered_at",
  },
  {
    label: "Redeemed At",
    key: "redeemed_at",
  },
]
