import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React, { useContext } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import PrivateRoute from "../components/private-route"
import SEO from "../components/seo"
import Layout from "../components/templates/layout"
import { AccountContext } from "../context/account"
import Collections from "../domain/collections"
import Customers from "../domain/customers"
import Discounts from "../domain/discounts"
import GiftCards from "../domain/gift-cards"
import Oauth from "../domain/oauth"
import Orders from "../domain/orders"
import DraftOrders from "../domain/orders/draft-orders"
import FailedOrders from "../domain/orders/failed-orders"
import Pricing from "../domain/pricing"
import Products from "../domain/products"
import Settings from "../domain/settings"
import StorefrontConfig from "../domain/storefront-config"
import Subscriptions from "../domain/subscriptions"

const IndexPage = () => {
  useHotkeys("g + o", () => navigate("/a/orders"))
  useHotkeys("g + p", () => navigate("/a/products"))
  const account = useContext(AccountContext)
  return (
    <Layout>
      <SEO title="Medusa" />
      <Router basepath="a" className="h-full">
        <PrivateRoute path="products/*" component={Products} />
        <PrivateRoute path="orders/*" component={Orders} />
        {!account?.isVendor && (
          <>
            <PrivateRoute path="oauth/:app_name" component={Oauth} />
            <PrivateRoute path="collections/*" component={Collections} />
            <PrivateRoute path="gift-cards/*" component={GiftCards} />
            <PrivateRoute path="subscriptions/*" component={Subscriptions} />
            <PrivateRoute path="draft-orders/*" component={DraftOrders} />
            <PrivateRoute path="failed-orders/*" component={FailedOrders} />
            <PrivateRoute path="discounts/*" component={Discounts} />
            <PrivateRoute path="customers/*" component={Customers} />
            <PrivateRoute path="pricing/*" component={Pricing} />
            <PrivateRoute
              path="storefront-config/*"
              component={StorefrontConfig}
            />
            <PrivateRoute path="settings/*" component={Settings} />
          </>
        )}
      </Router>
    </Layout>
  )
}

export default IndexPage
