import { capitalize } from "lodash"
import { ProductOption } from "../../types/shared"
import React from "react"
import IconTooltip from "../molecules/icon-tooltip"

export const useGridColumns = (product, isEditing, isSubscription) => {
  const defaultFields: any = [
    // { header: "Title", field: "title" },
    { header: "SKU", field: "sku" },
    // { header: "EAN", field: "ean" },
    { header: "Inventory", field: "inventory_quantity" },
  ]

  if (product?.redeem_method === "by_product_variant") {
    defaultFields.push({
      header: "Redeem Code (Pending)",
      field: "",
      formatter: (variant: any) => {
        const redemptionCodes = variant?.redemption_codes
        const total = redemptionCodes.length
        const pending = redemptionCodes.filter((i) => i.status === "Pending")
          .length
        const inventory = variant?.inventory_quantity
        return (
          <div className="flex gap-xsmall">
            {total}
            <span
              className={
                pending < inventory
                  ? "text-red-400 font-bold flex items-center gap-xsmall"
                  : "font-bold flex items-center gap-xsmall"
              }
            >
              ({pending})
              {pending < inventory && (
                <IconTooltip
                  className="text-red-400"
                  type="warning"
                  content={"Insufficient Pending Redemption Codes"}
                />
              )}
            </span>
          </div>
        )
      },
    })
  }

  if (isEditing) {
    const validOptions: any = []
    product.options.forEach((option) => {
      if (isSubscription && option.title === ProductOption.DURATION) {
        validOptions.push(option)
      } else if (!isSubscription && option.title !== ProductOption.DURATION) {
        validOptions.push(option)
      }
    })
    const optionColumns = validOptions.map((o) => ({
      header: o.title,
      field: "options",
      formatter: (variantOptions) => {
        const displayVal = variantOptions.find((val) => val.option_id === o.id)
        return displayVal?.value || " - "
      },
    }))
    return [
      ...optionColumns,
      {
        header: "Prices",
        field: "prices",
        formatter: (prices) =>
          prices.length > 0
            ? `${prices?.[0]?.currency_code?.toUpperCase()} ${(
                prices?.[0]?.amount / 100
              )?.toFixed(2)}`
            : "",
      },
      ...defaultFields,
      {
        header: "Visible",
        field: "is_visible",
        formatter: (value: boolean) => (value ? "Yes" : "No"),
      },
    ]
  } else {
    return [
      {
        header: "Variant",
        field: "options",
        formatter: (value) => {
          const options = value.map((v) => {
            if (v.value) {
              return capitalize(v.value)
            }
            return capitalize(v)
          })

          return options.join(" / ")
        },
        readOnly: true,
        headCol: true,
      },
      ...defaultFields,
    ]
  }
}
