import React, { useContext, useEffect, useState } from "react"
import { AccountContext } from "../../../../context/account"
import { Controller } from "react-hook-form"
import BodyCard from "../../../../components/organisms/body-card"
import Medusa from "../../../../services/api"
import { useProductForm } from "../form/product-form-context"
import Select from "../../../../components/molecules/select"

const Vendor = () => {
  const { control, watch } = useProductForm()

  const account = useContext(AccountContext)

  const [vendorList, setVendorList] = useState([])
  const fetchVendorList = async () => {
    const { data } = await Medusa.vendor.list()
    if (data.code === 200) {
      setVendorList(
        data.data
          .map(({ id, name }) => ({
            label: name,
            value: id,
          }))
          .concat({ label: "N/A", value: null })
      )
    }
  }
  useEffect(() => {
    fetchVendorList()
  }, [])

  return (
    <BodyCard title="Team">
      <div>
        <Controller
          control={control}
          name="vendor_id"
          render={({ onChange, value }) => {
            return (
              <Select
                label="Choose Team"
                placeholder=""
                clearSelected={true}
                options={vendorList}
                value={vendorList.find((v) => v.value === value)}
                onChange={(value) => {
                  onChange(value.value)
                }}
                isDisabled={!!account?.isVendor}
              />
            )
          }}
        />
      </div>
    </BodyCard>
  )
}

export default Vendor
