import { useAdminStore } from "medusa-react"
import * as React from "react"
import RawJSON from "../../../components/organisms/raw-json"
import { useProductForm } from "./form/product-form-context"
import General from "./sections/general"
import Images from "./sections/images"
import Prices from "./sections/prices"
import StockAndInventory from "./sections/stock-inventory"
import Surveys from "./sections/surveys"
import Variants from "./sections/variants"
import GrantDigitalAccess from "./sections/grant-digital-access"
import SubscriptionPlanOptions from "./sections/subscription-plan-options"
import TermsConditions from "./sections/terms-conditions"
import Medusa from "../../../services/api"
import { ProductType } from "../../../types/shared"
import Misc from "./sections/Misc"
import Vendor from "./sections/vendor"
import FreeSubscriber from "./sections/FreeSubscriber"
import RedemptionCode from "./sections/redemption-code"

type ProductFormProps = {
  product?: any
  isEdit?: boolean
}

const ProductForm = ({ product, isEdit = false }: ProductFormProps) => {
  const { isVariantsView } = useProductForm()
  const { store } = useAdminStore()
  const currencyCodes = store?.currencies.map((currency) => currency.code)

  const [showArticleSubscription, setShowArticleSubscription] = React.useState(
    false
  )

  React.useEffect(() => {
    if (
      product &&
      product.type &&
      product.type.value === ProductType.ARTICLE_SUBSCRIPTION
    ) {
      setShowArticleSubscription(true)
    } else {
      setShowArticleSubscription(false)
    }
  }, [product])

  console.log("raw product data", product)

  return (
    <>
      {/* <div className="absolute w-full h-full bg-sky-500 opacity-50 z-10 overflow-hidden" /> */}
      <div>
        <General isEdit={isEdit} product={product} showViewOptions={!isEdit}>
          {(isVariantsView || isEdit) && showArticleSubscription && (
            <div className="mt-large">
              <SubscriptionPlanOptions product={product} />
            </div>
          )}
        </General>
      </div>
      {(isVariantsView || isEdit) && (
        <div className="mt-large">
          <Variants
            isEdit={isEdit}
            product={product}
            isSubscription={showArticleSubscription}
          />
        </div>
      )}
      {(isVariantsView || isEdit) && showArticleSubscription && (
        <div className="mt-large">
          <GrantDigitalAccess product={product} />
        </div>
      )}
      {/* {(isVariantsView || isEdit) && showArticleSubscription && (
        <div className="mt-large">
          <SubscriptionPlanOptions product={product} />
        </div>

      )}
      {(isVariantsView || isEdit) && (

      )} */}
      {(isVariantsView || isEdit) && (
        <div className="mt-large">
          <TermsConditions product={product} />
        </div>
      )}
      {!isVariantsView && !isEdit && (
        <div className="mt-large">
          <Prices
            currencyCodes={currencyCodes}
            defaultCurrencyCode={store?.default_currency_code}
            defaultAmount={1000}
            defaultCaption={""}
          />
        </div>
      )}
      <div
        style={{
          display: showArticleSubscription ? "none" : "block",
        }}
      >
        <div className="mt-large">
          <Surveys isEdit={isEdit} product={product} />
        </div>
      </div>
      <div>
        {product?.variants?.length ? (
          <div className="mt-large">
            <Images />
          </div>
        ) : (
          <div className="py-large">
            <h6 className="inter-base-semibold text-blue-600">
              You have to create at least 1 variant to upload image
            </h6>
          </div>
        )}
      </div>
      <div
        style={{
          display: showArticleSubscription ? "none" : "block",
        }}
      >
        <div className="mt-large">
          <Misc />
        </div>
      </div>

      {/* <div className="mt-large">
        <StockAndInventory />
      </div> */}
      {/* <div className="mt-large">
        <RawJSON data={product} title="Raw product" />
      </div> */}

      {isEdit && (
        <div className="mt-large">
          <Vendor />
        </div>
      )}

      {isEdit && product.redeem_method === "by_product" && (
        <div className="mt-large">
          <RedemptionCode
            productId={product.id}
            redeemMethod={product.redeem_method}
            inventory={product?.variants?.reduce(
              (acc, next) => acc + next.inventory_quantity,
              0
            )}
          />
        </div>
      )}

      {(isVariantsView || isEdit) && showArticleSubscription && (
        <div className="mt-large">
          <FreeSubscriber product={product} />
        </div>
      )}

      <div className={"pt-large"}></div>
    </>
  )
}

export default ProductForm
