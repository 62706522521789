import React, { useState, useMemo } from "react"
import { RouteComponentProps, Router } from "@reach/router"
import { navigate } from "gatsby"

import PlusIcon from "../../../components/fundamentals/icons/plus-icon"
import BodyCard from "../../../components/organisms/body-card"
import TableViewHeader from "../../../components/organisms/custom-table-header"
import FailedOrderTable from "../../../components/templates/failed-order-table"
import DraftOrderDetails from "./details"
import NewOrder from "../new/new-order"

const VIEWS = ["orders", "failed"]

const FailedOrderIndex = () => {
  const view = "failed"
  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full flex flex-col grow">
        <BodyCard
          customHeader={
            <TableViewHeader
              views={VIEWS}
              setActiveView={(v) => {
                if (v === "orders") {
                  navigate(`/a/orders`)
                }
              }}
              activeView={view}
            />
          }
          // actionables={actions}
        >
          <FailedOrderTable />
        </BodyCard>
      </div>
      {/* {showNewOrder && (
        <NewOrder onDismiss={() => setShowNewOrder(false)} refresh />
      )} */}
    </div>
  )
}

export default FailedOrderIndex
