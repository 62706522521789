import { RouteComponentProps, useLocation } from "@reach/router"
import { useAdminDraftOrders } from "medusa-react"
import React, { useEffect, useState } from "react"
import { usePagination, useTable } from "react-table"
import Spinner from "../../atoms/spinner"
import Table, { TablePagination } from "../../molecules/table"
import useFailedOrderTableColumns from "./use-failed-order-column"
import { useFailedOrderFilters } from "./use-failed-order-filters"
import Medusa from "../../../services/api"
import CheckIcon from "../../fundamentals/icons/check-circle-icon"

const DEFAULT_PAGE_SIZE = 15

const FailedOrderTable: React.FC<RouteComponentProps> = () => {
  const location = useLocation()

  const {
    reset,
    paginate,
    setQuery: setFreeText,
    queryObject,
  } = useFailedOrderFilters(location.search, {})

  const filtersOnLoad = queryObject

  const offs = parseInt(filtersOnLoad?.offset) || 0
  const lim = parseInt(filtersOnLoad?.limit) || DEFAULT_PAGE_SIZE

  const [query, setQuery] = useState(filtersOnLoad?.query)
  const [numPages, setNumPages] = useState(0)
  const [cartsInfo, setCartsInfo] = useState({})

  // const { draft_orders, isLoading, isRefetching, count } = useAdminDraftOrders(
  //   queryObject
  // )

  const refetch = async () => {
    const { data } = await Medusa.carts.getFailedOrders(queryObject)
    setCartsInfo(data || {})
  }

  useEffect(() => {
    async function fetchFailedOrders() {
      refetch()
    }
    fetchFailedOrders()
  }, [queryObject])

  useEffect(() => {
    const controlledPageCount = Math.ceil(cartsInfo?.count! / lim)
    setNumPages(controlledPageCount)
  }, [cartsInfo?.count, queryObject])

  const [columns] = useFailedOrderTableColumns()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: cartsInfo?.carts || [],
      manualPagination: true,
      initialState: {
        pageSize: lim,
        pageIndex: offs / lim,
      },
      pageCount: numPages,
      autoResetPage: false,
    },
    usePagination
  )

  // Debounced search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query) {
        setFreeText(query)
        gotoPage(0)
      } else {
        // if we delete query string, we reset the table view
        reset()
      }
    }, 400)

    return () => clearTimeout(delayDebounceFn)
  }, [query])

  const handleNext = () => {
    if (canNextPage) {
      paginate(1)
      nextPage()
    }
  }

  const handlePrev = () => {
    if (canPreviousPage) {
      paginate(-1)
      previousPage()
    }
  }

  return (
    <div className="w-full h-full overflow-y-auto flex flex-col justify-between">
      {!cartsInfo?.carts ? (
        <div className="w-full pt-2xlarge flex items-center justify-center">
          <Spinner size={"large"} variant={"secondary"} />
        </div>
      ) : (
        <>
          <Table
            filteringOptions={[]}
            // enableSearch
            handleSearch={setQuery}
            searchValue={query}
            {...getTableProps()}
          >
            <Table.Head>
              {headerGroups?.map((headerGroup, index) => (
                <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((col, headerIndex) => (
                    <Table.HeadCell
                      className="w-[100px]"
                      {...col.getHeaderProps()}
                    >
                      {col.render("Header")}
                    </Table.HeadCell>
                  ))}
                </Table.HeadRow>
              ))}
            </Table.Head>
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return <CartRow row={row} refetch={refetch} />
              })}
            </Table.Body>
          </Table>
          <TablePagination
            count={cartsInfo?.count}
            limit={queryObject.limit || 15}
            offset={queryObject.offset || 0}
            pageSize={queryObject.offset + rows.length}
            title="Failed Orders"
            currentPage={pageIndex + 1}
            pageCount={pageCount}
            nextPage={handleNext}
            prevPage={handlePrev}
            hasNext={canNextPage}
            hasPrev={canPreviousPage}
          />
        </>
      )}
    </div>
  )
}

const CartRow = ({ row, refetch }) => {
  const cart = row.original
  const cartId = cart.id
  const is_resolved = !!cart?.metadata?.fail_resolved

  return (
    <Table.Row
      color={"inherit"}
      forceDropdown
      actions={[
        {
          label: "Resolve",
          onClick: async () => {
            await Medusa.carts.update(cartId, {
              metadata: {
                fail_resolved: !is_resolved,
              },
            })
            refetch()
          },
          icon: <CheckIcon size={20} />,
        },
      ]}
      {...row.getRowProps()}
    >
      {row.cells.map((cell, index) => {
        return cell.render("Cell", { index })
      })}
    </Table.Row>
  )
}

export default FailedOrderTable
