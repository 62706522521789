import React, { useMemo, useState } from "react"
import BodyCard from "../../../../components/organisms/body-card"
import useClipboard from "../../../../hooks/use-clipboard"
import useNotification from "../../../../hooks/use-notification"
import DupIcon from "../../../../components/fundamentals/icons/duplicate-icon"
import PlusIcon from "../../../../components/fundamentals/icons/plus-icon"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import medusaRequest from "../../../../services/request"
import GenerateCodesModal from "./generate-codes"
import useImperativeDialog from "../../../../hooks/use-imperative-dialog"
import moment from "moment"

type UniqueCodesProps = {
  // discounts: Discount
  parentId: string
  discounts: any[]
  count: number
  fetchChildDiscount: () => Promise<void>
}

const UniqueCodes: React.FC<UniqueCodesProps> = ({
  parentId,
  discounts,
  count,
  fetchChildDiscount,
}) => {
  const notification = useNotification()

  const [sort, setSort] = useState("created_at")

  const [showGenerateCodes, setShowGenerateCodes] = useState(false)
  const dialog = useImperativeDialog()

  const onDelete = async () => {
    const shouldDelete = await dialog({
      heading: "Delete Product",
      text: "Are you sure you want to delete this product",
    })
    if (shouldDelete) {
      const path = `/admin/discounts/${parentId}/batch-delete`
      const { data } = await medusaRequest("POST", path, {})
      await fetchChildDiscount()
    }
  }

  const sortedDiscounts = useMemo(() => {
    if (sort === "created_at") {
      return discounts.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
    } else if (sort === "usage_count") {
      return discounts.sort((a, b) => (a.usage_count < b.usage_count ? 1 : -1))
    }
    return discounts
  }, [sort, discounts])

  const [, handleCopy] = useClipboard(
    sortedDiscounts.map((discount) => discount.code).join("\n"),
    {
      successDuration: 1000,
      onCopied: () => notification("Success", "Code copied", "success"),
    }
  )

  return (
    <BodyCard
      title="Unique Codes"
      subtitle={`Number of codes: ${count}`}
      actionables={[
        {
          label: "Copy all codes",
          onClick: () => {
            handleCopy()
          },
          icon: <DupIcon size={"20"} />,
        },
        {
          label: "Generate new codes",
          onClick: () => {
            setShowGenerateCodes(true)
          },
          icon: <PlusIcon size={"20"} />,
        },
        {
          label: "Delete all codes (unused)",
          onClick: onDelete,
          icon: <TrashIcon size={"20"} />,
        },
      ]}
    >
      <div className="border-b py-1  grid grid-cols-3">
        <div>Code</div>
        <div className="flex">
          <div
            onClick={() => setSort("usage_count")}
            className={`${
              sort === "usage_count" ? "font-bold text-blue-500" : ""
            } cursor-pointer`}
          >
            Usage
          </div>
        </div>
        <div className="flex">
          <div
            onClick={() => setSort("created_at")}
            className={`${
              sort === "created_at" ? "font-bold text-blue-500" : ""
            } cursor-pointer`}
          >
            Created
          </div>
        </div>
      </div>
      {sortedDiscounts.map((discount) => (
        <div className="border-b py-1 grid grid-cols-3" key={discount.code}>
          <div>{discount.code}</div>
          <div>{discount.usage_count === 1 ? "Used" : ""}</div>
          <div>
            {moment(discount.created_at)
              .zone("+0800")
              .format("YYYY-MM-DD HH:mm:ss ([HKT])")}
          </div>
        </div>
      ))}
      {showGenerateCodes && (
        <GenerateCodesModal
          parentId={parentId}
          handleCancel={() => setShowGenerateCodes(false)}
          fetchChildDiscount={fetchChildDiscount}
        />
      )}
    </BodyCard>
  )
}

export default UniqueCodes
