import React from "react"

const FileUploadButton = ({ accept, handleSubmit, text }) => {
  const buttonStyle = {
    display: "inline-block",
    backgroundColor: "#f44336",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    height: "100%",
  }

  return (
    <label style={buttonStyle}>
      <input
        type="file"
        accept={accept}
        onChange={handleSubmit}
        onClick={(e) => {
          const element = e.target as HTMLInputElement
          element.value = ""
        }}
        className="hidden"
      />
      {text || "Upload"}
    </label>
  )
}

export default FileUploadButton
