import moment from "moment"
import React, { useMemo } from "react"
import { getColor } from "../../../utils/color"
import StatusDot from "../../fundamentals/status-indicator"
import CustomerAvatarItem from "../../molecules/customer-avatar-item"
import Table from "../../molecules/table"

const useFailedOrderTableColumns = () => {
  const decideStatus = (status) => {
    switch (status) {
      case "completed":
        return <StatusDot variant="success" title={"Completed"} />
      default:
        return <StatusDot variant="primary" title={"Open"} />
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date added",
        accessor: "created_at",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>
            {moment(value).format("YYYY-MM-DD HH:mm")}
          </Table.Cell>
        ),
      },
      {
        Header: "Customer",
        accessor: "email",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>{value}</Table.Cell>
        ),
      },
      {
        Header: "Product",
        accessor: "items.0.title",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index} className="max-w-[200px] truncate pr-4">
            {value}
          </Table.Cell>
        ),
      },
      {
        Header: "Varaint",
        accessor: "items.0.variant.title",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index} className="max-w-[200px] truncate">
            {value}
          </Table.Cell>
        ),
      },
      {
        Header: "SKU",
        accessor: "items.0.variant.sku",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{value}</Table.Cell>
        ),
      },
      {
        Header: "Qty",
        accessor: "items.0.quantity",
        Cell: ({ cell: { value }, index }) => (
          <Table.Cell key={index}>{value}</Table.Cell>
        ),
      },
      {
        Header: "Payment Method",
        accessor: "payment.provider_id",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>{value || "N/A"}</Table.Cell>
        ),
      },
      {
        Header: "Amount",
        accessor: "payment.amount",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>
            {value ? `HK$${value / 100}` : "N/A"}
          </Table.Cell>
        ),
      },
      {
        Header: "Fail Resolved",
        accessor: "metadata.fail_resolved",
        Cell: ({ row, cell: { value }, index }) => (
          <Table.Cell key={index}>{value ? "✅" : ""}</Table.Cell>
        ),
      },
    ],
    []
  )

  return [columns]
}

export default useFailedOrderTableColumns
