import React, { useContext, useEffect, useState } from "react"
import { AccountContext } from "../../../../context/account"
import { Controller, useFieldArray } from "react-hook-form"
import BodyCard from "../../../../components/organisms/body-card"
import { useProductForm } from "../form/product-form-context"
import Select from "../../../../components/molecules/select"
import { buildTitle } from "../../../../utils/string-helper"
import Medusa from "../../../../services/api"
import useNotification from "../../../../hooks/use-notification"
import { intersectionWith, isEqual } from "lodash"
import Table from "../../../../components/molecules/table"
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"
import moment from "moment"
import "moment/locale/zh-hk"
import useImperativeDialog from "../../../../hooks/use-imperative-dialog"
import FileUploadButton from "../../../../components/upload-button"
import IconTooltip from "../../../../components/molecules/icon-tooltip"
import { redemptionCodeExportPropertiesDescriptors } from "../../../../constants/descriptors"
import { CSVLink } from "react-csv"

const RedemptionCode = ({
  productId = undefined,
  variantId = undefined,
  redeemMethod,
  inventory,
}) => {
  let id, redemptionCodeAPIs
  switch (redeemMethod) {
    case "by_product":
      id = productId
      redemptionCodeAPIs = Medusa.products.redemptionCodes
      break
    case "by_product_variant":
      id = variantId
      redemptionCodeAPIs = Medusa.products.variants.redemptionCodes
      break
    default:
      id = null
      break
  }
  /* const productRulesPolicyIdList = product.rules.map((r) =>
    parseInt(r.policy_id)
  )
  if (productRulesPolicyIdList.length === 0) {
    return null
  } */
  const dialog = useImperativeDialog()
  const notification = useNotification()

  const account = useContext(AccountContext)

  const exportCSVHeaders = redemptionCodeExportPropertiesDescriptors

  const [redemptionCodes, setRedemptionCodes] = useState<any[]>([])
  const [codeTotalCount, setCodeTotalCount] = useState<number>(0)
  const [codePendingCount, setCodePendingCount] = useState<number>(0)
  const [newRedemptionCode, setNewRedemptionCode] = useState("")

  console.log(
    "🚀 ~ file: redemption-code.tsx:16 ~ redemptionCodes ~ ",
    redemptionCodes
  )

  const fetchRedemptionCode = async () => {
    const formatRedemptionCodes = (initial) => {
      const formatted = initial.map((i) => ({
        ...i,
        created_at: i?.created_at
          ? new Date(i?.created_at)?.toLocaleString("zh-HK", {
              timeZone: "Asia/Hong_Kong",
            })
          : null,
        delivered_at: i?.delivered_at
          ? new Date(i?.delivered_at)?.toLocaleString("zh-HK", {
              timeZone: "Asia/Hong_Kong",
            })
          : null,
        redeemed_at: i.redeemed_at
          ? new Date(i.redeemed_at)?.toLocaleString("zh-HK", {
              timeZone: "Asia/Hong_Kong",
            })
          : null,
        updated_at: i?.updated_at
          ? new Date(i?.updated_at)?.toLocaleString("zh-HK", {
              timeZone: "Asia/Hong_Kong",
            })
          : null,
      }))
      return formatted
    }
    const { data } = await redemptionCodeAPIs.list(id)
    if (data?.code === 200) {
      const redemptionCodes = data?.data
      console.log(
        "🚀 ~ file: redemption-code.tsx:95 ~ fetch ~ redemption codes:",
        formatRedemptionCodes(redemptionCodes)
      )
      setRedemptionCodes(formatRedemptionCodes(redemptionCodes))
      setCodeTotalCount(redemptionCodes.length)
      setCodePendingCount(
        redemptionCodes.filter((i) => i.status === "Pending").length
      )
    }
  }

  const handleDeleteRedemptionCode = async (rc) => {
    const shouldDelete = await dialog({
      heading: "Delete Redemption Code",
      text: "Are you sure you want to delete this redemption code?",
    })

    if (shouldDelete) {
      try {
        const { data } = await Medusa.redemptionCodes.delete(rc.id)
        if (data.code === 200)
          notification("Success", "Redemption Code Deleted", "success")
      } catch (e) {
        notification("Error", e?.message, "error")
      }
      fetchRedemptionCode()
    }
  }

  const handleRedeemRedemptionCode = async (rc) => {
    const shouldRedeem = await dialog({
      heading: "Redeem Redemption Code",
      text: "Are you sure you want to redeem this redemption code?",
    })
    if (shouldRedeem) {
      try {
        const { data } = await Medusa.redemptionCodes.update(rc.id, {
          status: "Redeemed",
        })
        if (data.code === 200)
          notification("Success", "Redemption Code Redeemed", "success")
      } catch (e) {
        notification("Error", e?.message, "error")
      }
      setNewRedemptionCode("")
      fetchRedemptionCode()
    }
  }

  const handleCreateRedemptionCode = async () => {
    try {
      const { data } = await redemptionCodeAPIs.create(id, {
        code: newRedemptionCode,
      })
      if (data.code === 200)
        notification("Success", "Redemption Code Saved", "success")
    } catch (e) {
      notification("Error", e?.message, "error")
    }
    setNewRedemptionCode("")
    fetchRedemptionCode()
  }

  const handleSubmitCSV = async (event) => {
    console.log("handleSubmitCSV", event.target.value.length)
    if (!event.target.value.length) return

    const formData = new FormData()
    formData.append("remdemptionCodeCSV", event.target.files[0])

    await redemptionCodeAPIs
      .uploadCSV(id, formData)
      .then(function (response) {
        const { data } = response
        if (data.code === 200)
          notification("Success", "Redemption Code Saved", "success")
      })
      .catch(function (e) {
        //handle error
        notification("Error", e?.message, "error")
        console.log(e)
      })
      .finally(function () {
        fetchRedemptionCode()
      })
  }

  useEffect(() => {
    fetchRedemptionCode()
  }, [])

  return (
    <BodyCard>
      <div className="flex justify-between mb-5">
        <h1 className="inter-xlarge-semibold text-grey-90">Redemption Codes</h1>
        <div className="text-sm">
          <CSVLink
            headers={exportCSVHeaders}
            data={redemptionCodes}
            filename={"redemption-codes.csv"}
          >
            <button className="bg-black text-white h-full mx-3 px-4 py-2 rounded cursor-pointer">
              Export CSV
            </button>
          </CSVLink>
          {!account?.isVendor && (
            <FileUploadButton
              accept={".csv"}
              handleSubmit={handleSubmitCSV}
              text={"Upload CSV"}
            />
          )}
        </div>
      </div>
      <div className="mb-5">
        <h1 className="inter-large-semibold text-grey-90 mb-2">
          Inventory: {inventory}
        </h1>
        <h1 className="inter-large-semibold text-grey-90 flex items-center gap-xsmall">
          Total Codes (Pending): {codeTotalCount} ({codePendingCount}){" "}
          {codePendingCount < inventory && (
            <IconTooltip
              className="text-red-400"
              type="warning"
              size={24}
              content={"Insufficient Pending Redemption Codes"}
            />
          )}
        </h1>
      </div>
      <div className="border-b py-1  grid grid-cols-6 font-bold">
        <div className="col-span-2">Redemption Code</div>
        <div className="col-span-2">
          <div>Order Num / Email</div>
        </div>
        <div>Status</div>
        <div></div>
      </div>
      {redemptionCodes.map((rc) => (
        <div
          className="border-b py-1 grid grid-cols-6 items-center"
          key={rc.id}
        >
          <div className="col-span-2">{rc?.code}</div>
          <div className="col-span-2">
            <div>{rc?.order?.order_display_id}</div>
            <div>{rc?.order?.email}</div>
          </div>
          <div>{rc?.status}</div>
          <div className="flex justify-end gap-x-2">
            {rc?.status === "Pending" && !account?.isVendor && (
              <button
                className="bg-red-600 px-4 text-white w-20"
                onClick={async () => {
                  await handleDeleteRedemptionCode(rc)
                }}
              >
                delete
              </button>
            )}
            {rc?.status === "Delivered" && (
              <button
                className="bg-green-600 px-4 text-white w-20"
                onClick={async () => {
                  await handleRedeemRedemptionCode(rc)
                }}
              >
                redeem
              </button>
            )}
          </div>
        </div>
      ))}
      {!account?.isVendor && (
        <div className="py-2 grid grid-cols-3">
          <input
            className="bg-white px-2 border "
            placeholder="new redemption code"
            value={newRedemptionCode}
            onChange={(e) => setNewRedemptionCode(e.target.value)}
          />
          <div /* className="flex justify-end" */>
            <button
              className="bg-green-600 px-4 mx-3 text-white"
              onClick={async () => await handleCreateRedemptionCode()}
            >
              save code
            </button>
          </div>
        </div>
      )}
    </BodyCard>
  )
}

export default RedemptionCode
